<template>
  <YoTable
    :api-endpoint="apiEndpoint"
    :title-tbl="this.$t('COMPANY OWN')"
    :sub-title-tbl="this.$t('Land Vehicles')"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :bottom-label=" this.$t('KeteranganTable') + '~'+ this.$t('Form Land Vehicle')+'~'"
    bottom-icon="GitMergeIcon"
    info-page-ability="Manage (All / Agen All)."
    placeholder-search="PLAT NO POL"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompTableT1T2.vue'

export default {
  components: {
    YoTable,
  },
  data() {
    return {
      apiEndpoint: 'land_vehicle',
      fields: [
        {
          minWidth: '130px',
          key: 'group.name',
          label: 'GROUP',
          placeholder: '',
          sortable: true,
          input: { type: 'input-noarea', disabled: true },
        },
        {
          key: 'plat_nopol',
          label: 'Plat No POL',
          placeholder: 'Plat Nomor Polisi',
          sortable: true,
          input: { type: 'input-noarea' },
        },
        {
          key: 'dm_land_vehicle.name',
          columnKey: 'dm_land_vehicle_id',
          label: 'Nama Kendaraan [Land Vehicle]',
          sortable: true,
          input: {
            type: 'select',
            requestAPI: 'allow',
            unique1: 'model',
            unique2: 'capacity',
            optionsApiEndpoint: 'dm_land_vehicle',
            resolveOptionsResponse(response) {
              return response.data.dm_land_vehicles.map(value => ({ label: `${value.name} - Model : ${value.model} - Capacity : ${value.capacity}`, value: value.id }))
            },
            reduce({ value }) {
              return value // as ID DATA
            },
          },
        },
        {
          key: 'dm_land_vehicle.model',
          label: 'Model',
          placeholder: 'Otomatis terisi',
          sortable: true,
          input: { type: 'input-noarea', disabled: true },
        },
        {
          key: 'dm_land_vehicle.capacity',
          label: 'Capacity (LTR)',
          placeholder: 'Otomatis terisi',
          sortable: true,
          input: { type: 'input-noarea', disabled: true },
        },
        // {
        //   key: 'model',
        //   label: 'Model',
        //   placeholder: 'Model',
        //   sortable: true,
        //   input: { type: 'input-noarea' },
        // },
        // {
        //   key: 'capacity',
        //   label: 'Capacity ( LTR )',
        //   placeholder: 'Hanya Angka',
        //   sortable: true,
        //   input: { type: 'number-append', append: 'Ltr' },
        // },
      ],
    }
  },
  computed: {
    newDataTemplate() {
      return {
        // key: 'dm_land_vehicle_id',
        dm_land_vehicle_id: '', // temp
        // TODO : REUSABLE REQUEST API SET INIT
        // dm_land_vehicle: {
        //   name: '',
        // },
        plat_nopol: '',
        // model: '',
        // capacity: 0,
      }
    },
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.land_vehicles
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC',
        },
      }
    },
  },
}
</script>
